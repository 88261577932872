import moment from 'moment'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { request, businessCode } from './index'

export type OrderType = {
  id: number
  service: string
  service_id: number
  resource: string
  resource_id: number
  price: number
  date: string
  day: number
  time: string
  month: number
  year: number
  comment: string
  prepaid: number
  status: number
  allow_cancel: boolean
  allow_update: boolean
}

type StateType = {
  all: Array<OrderType>
  step: number
  comment: string
  fetch: boolean
  cancelId: number | null
  editId: number | null
  payment: {
    iframe: string
    orderId: number | null
    amount: number
    isPrepay: boolean
  }
}

const initialState: StateType = {
  all: [],
  step: 1,
  comment: '',
  fetch: true,
  cancelId: null,
  editId: null,
  payment: {
    iframe: '',
    orderId: null,
    amount: 0,
    isPrepay: false,
  }
}

export const orderSlice = createSlice({
  name: '$order',

  initialState,

  reducers: {
    all: (state: StateType, action: PayloadAction<Array<any>>) => {
      state.all = action.payload.map(item => {
        return {
          id: item.order_id,
          service: item.name,
          service_id: item.item_id,
          resource: item.resource_name,
          resource_id: item.resource_id,
          price: Number(item.item_data.price),
          date: moment(item.start_time).format('YYYY-MM-DD HH:mm'),
          day: Number(moment(item.start_time).format('D')),
          time: moment(item.start_time).format('HH:mm'),
          month: Number(moment(item.start_time).format('M')),
          year: Number(moment(item.start_time).format('YYYY')),
          comment: item.notes,
          prepaid: Number(item.prepaid),
          status: item.order_status,
          allow_cancel: item.allow_cancel,
          allow_update: item.allow_update,
        }
      })
    },

    step: (state: StateType, action: PayloadAction<number>) => {
      state.step = action.payload
    },

    comment: (state: StateType, action: PayloadAction<string>) => {
      state.comment = action.payload
    },

    fetch: (state: StateType, action: PayloadAction<boolean>) => {
      state.fetch = action.payload
    },

    cancelId: (state: StateType, action: PayloadAction<number | null>) => {
      state.cancelId = action.payload
    },

    editId: (state: StateType, action: PayloadAction<number | null>) => {
      state.editId = action.payload
    },

    payment: (state: StateType, action: PayloadAction<{iframe: string, orderId: number | null, amount: number, isPrepay: boolean}>) => {
      state.payment = action.payload
    },

    reset: (state: StateType) => {
      state.step = 1
      state.comment = ''
      state.cancelId = null
      state.editId = null
      state.payment = {
        iframe: '',
        orderId: null,
        amount: 0,
        isPrepay: false,
      }
    },
  },
})

const api = {
  book: (params: object) => {
    return request(`book/id/${businessCode()}`, 'POST', params)
  },

  all: (params: object) => {
    return request(`orders/id/${businessCode()}`, 'POST', params)
  },

  cancel: (params: object) => {
    return request(`remove/id/${businessCode()}`, 'POST', params)
  },

  update: (params: object) => {
    return request(`edit/id/${businessCode()}`, 'POST', params)
  },

  paid: (params: object) => {
    return request(`order_paid/id/${businessCode()}`, 'POST', params)
  },
}

const order = {
  reducer: orderSlice.reducer,
  set: orderSlice.actions,
  api,
}

export default order