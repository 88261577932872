import { useAppDispatch, useAppSelector } from 'store'
import $wlist from 'store/wlist'

import App from 'components/App'

import styles from './DialogWlistTime.module.scss'
import { useEffect } from 'react'

const DialogWlistTime: React.FC = () => {
  const dispatch = useAppDispatch()
  const dialog = useAppSelector(({ $wlist }) => $wlist.dialog)
  const selectedFrom = useAppSelector(({ $wlist }) => $wlist.selectedFrom)
  const selectedTo = useAppSelector(({ $wlist }) => $wlist.selectedTo)
  const times = useAppSelector($wlist.get.times)

  const selectedTime = dialog === 'from' ? selectedFrom : selectedTo

  useEffect(() => {
    if (selectedFrom) {
      if (!times.includes(selectedFrom)) {
        dispatch($wlist.set.selectedFrom(null))
      }
    }

    if (selectedTo) {
      if (!times.includes(selectedTo)) {
        dispatch($wlist.set.selectedTo(null))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [times, selectedFrom, selectedTo])

  const handleTimeToggle = (time: string) => () => {
    if (dialog === 'from') {
      dispatch($wlist.set.selectedFrom(time === selectedFrom ? null : time))
    } else {
      dispatch($wlist.set.selectedTo(time === selectedTo ? null : time))
    }

    handleClose()
  }

  const isDisabled = (time: string) => {
    if (dialog === 'from' && selectedTo) {
      return times.indexOf(time) >= times.indexOf(selectedTo)
    }

    if (dialog === 'to' && selectedFrom) {
      return times.indexOf(time) <= times.indexOf(selectedFrom)
    }

    return false
  }

  const handleClose = () => {
    dispatch($wlist.set.dialog(null))
  }

  return (
    <App.Dialog open={dialog === 'from' || dialog === 'to'} onClose={handleClose}>
      <App.Flex column gap={6} className={styles.container}>
        <App.Flex wrap gap={2}>
          {times.map((time: string) => (
            <App.Badge key={time} active={selectedTime === time} disabled={isDisabled(time)} value={time} onClick={handleTimeToggle(time)} className={styles.time}>
              {time}
            </App.Badge>
          ))}
        </App.Flex>
      </App.Flex>
    </App.Dialog>
  )
}

export default DialogWlistTime