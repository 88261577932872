import values from 'utils/values'

import { Props } from './AppFlex'

import global from 'scss/global.module.scss'
import styles from './AppFlex.module.scss'

const helper = {
  init: (props: Props) => {
    return [styles.flex]
  },

  direction: (props: Props) => {
    const defaultValue = styles.row
    const value = values.class(props.direction)
    return (value ? styles[value] : null)
      ?? (props.row ? styles.row : null)
      ?? (props.column ? styles.column : null)
      ?? defaultValue
  },

  justify: (props: Props) => {
    const defaultValue = styles.jNormal
    const value = values.class(props.justifyContent)
    return (value ? styles[value] : null)
      ?? (props.center ? styles.jCenter : null)
      ?? (props.jCenter ? styles.jCenter : null)
      ?? (props.jStart ? styles.jStart : null)
      ?? (props.jEnd ? styles.jEnd : null)
      ?? (props.jBetween ? styles.jBetween : null)
      ?? (props.jAround ? styles.jAround : null)
      ?? (props.jNormal ? styles.jNormal : null)
      ?? defaultValue
  },

  align: (props: Props) => {
    const defaultValue = styles.aNormal
    const value = values.class(props.alignItems)
    return (value ? styles[value] : null)
      ?? (props.center ? styles.aCenter : null)
      ?? (props.aCenter ? styles.aCenter : null)
      ?? (props.aStart ? styles.aStart : null)
      ?? (props.aEnd ? styles.aEnd : null)
      ?? (props.aStretch ? styles.aStretch : null)
      ?? (props.aNormal ? styles.aNormal : null)
      ?? defaultValue
  },

  gap: (props: Props) => {
    const defaultValue = null
    const value = values.classWithPrefix(props.gap, 'g')
    return (value ? global[value] : defaultValue)
  },

  flex: (props: Props) => {
    const defaultValue = null
    const value = values.classWithPrefix(props.flex, 'f')
    return (value ? global[value] : defaultValue)
  },

  widthClass: (props: Props) => {
    const defaultValue = global.wAuto
    return (props.full ? global.wFull : null)
      ?? (props.fullWidth ? global.wFull : null)
      ?? (props.halfWidth ? global.wHalf : null)
      ?? defaultValue
  },

  heightClass: (props: Props) => {
    const defaultValue = global.hAuto
    return (props.full ? global.hFull : null)
      ?? (props.fullHeight ? global.hFull : null)
      ?? (props.halfHeight ? global.hHalf : null)
      ?? defaultValue
  },

  wrap: (props: Props) => {
    const defaultValue = styles.nowrap
    return (props.wrap ? styles.wrap : null)
    ?? defaultValue
  },

  width: (props: Props) => {
    const defaultValue = null
    const value = values.px(props.width)
    return value ?? defaultValue
  },

  height: (props: Props) => {
    const defaultValue = null
    const value = values.px(props.height)
    return value ?? defaultValue
  },

  scroll: (props: Props) => {
    const defaultValue = null
    return props.scroll ? styles.scroll : defaultValue
  },

  absolute: (props: Props) => {
    const defaultValue = null
    return props.absolute ? styles.absolute : defaultValue
  },

  padding: (props: Props) => {
    const defaultValue = null
    const result = []

    if (props.padding) {
      const value = values.padding(props.padding)
      if (value) {
        if (value.length === 1) {
          result.push(global[`p${value[0]}`])
        }

        if (value.length === 2) {
          result.push(global[`px${value[0]}`])
          result.push(global[`py${value[1]}`])
        }

        if (value.length === 3) {
          result.push(global[`pt${value[0]}`])
          result.push(global[`py${value[1]}`])
          result.push(global[`pb${value[2]}`])
        }

        if (value.length === 4) {
          result.push(global[`pt${value[0]}`])
          result.push(global[`pr${value[1]}`])
          result.push(global[`pb${value[2]}`])
          result.push(global[`pl${value[3]}`])
        }
      }
    }

    if (props.paddingX) {
      result.push(global[`px${props.paddingX}`])
    }

    if (props.paddingY) {
      result.push(global[`py${props.paddingY}`])
    }

    if (props.paddingTop) {
      result.push(global[`pt${props.paddingTop}`])
    }

    if (props.paddingBottom) {
      result.push(global[`pb${props.paddingBottom}`])
    }

    if (props.paddingRight) {
      result.push(global[`pr${props.paddingRight}`])
    }

    if (props.paddingLeft) {
      result.push(global[`pl${props.paddingLeft}`])
    }

    return result.length ? result : defaultValue
  },
}

export default helper