import App from 'components/App'

const Home: React.FC = () => {
  
  return (
    <App.Flex column center>
    </App.Flex>
  )
}

export default Home