import AppBadge from './AppBadge'
import AppButton from './AppButton'
import AppCard from './AppCard'
import AppDialog from './AppDialog'
import AppFlex from './AppFlex'
import AppIcon from './AppIcon'
import AppInput from './AppInput'
import AppLoader from './AppLoader'
import AppSelect from './AppSelect'
import AppTag from './AppTag'
import AppText from './AppText'
import AppTextarea from './AppTextarea'

const App = {
  Badge: AppBadge,
  Button: AppButton,
  Card: AppCard,
  Dialog: AppDialog,
  Flex: AppFlex,
  Icon: AppIcon,
  Input: AppInput,
  Loader: AppLoader,
  Select: AppSelect,
  Tag: AppTag,
  Text: AppText,
  Textarea: AppTextarea,
}

export default App