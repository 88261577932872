import values from 'utils/values'

import { Props } from './AppLoader'

import styles from './AppLoader.module.scss'

const helper = {
  init: (props: Props) => {
    return [styles.loader]
  },

  width: (props: Props) => {
    const defaultValue = undefined
    const value = values.px(props.size)
    return value ?? defaultValue
  },

  size: (props: Props) => {
    const defaultValue = null
    return (props.button && !props.large && !props.small ? styles.button : null)
      ?? (props.button && props.large ? styles.buttonLarge : null)
      ?? (props.button && props.small ? styles.buttonSmall : null)
      ?? (props.large ? styles.large : null)
      ?? (props.small ? styles.small : null)
      ?? (props.xl ? styles.xl : null)
      ?? (props.xs ? styles.xs : null)
      ?? defaultValue
  },

  colorClass: (props: Props) => {
    const defaultValue = styles.primary
    return (props.primary ? styles.primary : null)
      ?? (props.highlight ? styles.highlight : null)
      ?? defaultValue
  },

  alternate: (props: Props) => {
    const defaultValue = null
    return (props.alternate ? styles.alternate : defaultValue)
  },
}

export default helper