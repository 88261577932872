import React from 'react'

import cn from 'classnames'

import helper from './helper'

export type Props = {
  size?: number | Array<number>
  button?: boolean
  large?: boolean
  small?: boolean
  xl?: boolean
  xs?: boolean
  primary?: boolean
  highlight?: boolean
  alternate?: boolean
  style?: React.CSSProperties
}

const AppLoader: React.FC<Props> = (props) => {
  const classes = [
    ...helper.init(props),
    helper.size(props),
    helper.colorClass(props),
    helper.alternate(props),
  ]

  const styles = {
    width: helper.width(props),
    ...(props.style ?? {}),
  }

  return (
    <div className={cn(classes)} style={styles} />
  )
}

export default AppLoader