import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import cn from 'classnames'

import { useAppDispatch, } from 'store'
import $wlist, { WlistType } from 'store/wlist'

import App from 'components/App'

import styles from './HomeWlistItem.module.scss'

export type Props = {
  item: WlistType
}

const HomeWlistItem: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const [expand, setExpand] = useState(false)

  const handleExpand = () => {
    setExpand(!expand)
  }

  const handleCancel = () => {
    dispatch($wlist.set.cancelId(item.id))
  }

  return (
    <App.Card active>
      <App.Flex column fullWidth gap={4}>
        <App.Flex row aCenter jBetween className={styles.title} onClick={handleExpand}>
          <App.Text>{item.service}</App.Text>
          <App.Icon icon="chevron-down" style={{ transform: `rotate(${expand ? 180 : 0}deg)` }} />
        </App.Flex>

        <App.Flex fullWidth column>
          <App.Flex column gap={4} className={cn(styles.details, {[styles.hide]: expand})}>
            <App.Flex column gap={2}>
              <App.Text xs gray>{t('Details')}:</App.Text>

              <App.Flex row wrap gap={2}>
                <App.Tag icon="clock">{item.day} {t(moment(item.date).format('MMMM'))}, {item.from} - {item.to}</App.Tag>
                {item.resource !== '' ? <App.Tag icon="user">{item.resource}</App.Tag> : null}
              </App.Flex>
            </App.Flex>

            {item.allow_cancel ? (
              <App.Flex className={cn(styles.line, styles.border)}>
              </App.Flex>
            ) : null}
          </App.Flex>

          <App.Flex column gap={4} className={cn(styles.expand, {[styles.show]: expand})}>
            <App.Flex column fullWidth gap={3}>
              <App.Flex row aCenter jBetween gap={2}>
                <App.Text xs gray>{t('Service')}:</App.Text>
                <App.Tag>{item.service}</App.Tag>
              </App.Flex>

              {item.resource !== '' ? (
                <App.Flex row aCenter jBetween gap={2}>
                  <App.Text xs gray>{t('Specialist')}:</App.Text>
                  <App.Tag icon="user">{item.resource}</App.Tag>
                </App.Flex>
              ) : null}

              <App.Flex row aCenter jBetween gap={2}>
                <App.Text xs gray>{t('Date')}:</App.Text>
                <App.Tag icon="clock">{item.day} {t(moment(item.date).format('MMMM'))}, {item.from} - {item.to}</App.Tag>
              </App.Flex>
            </App.Flex>
          </App.Flex>
        </App.Flex>

        {item.allow_cancel ? (
          <App.Flex row aCenter gap={2}>
            <App.Button small outlined flex={1} variant="danger" icon="trash" onClick={handleCancel}>{t('Cancel')}</App.Button>
          </App.Flex>
        ) : null}
      </App.Flex>
    </App.Card>
  )
}

export default HomeWlistItem