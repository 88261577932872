import React from 'react'

import cn from 'classnames'

import helper from './helper'

export type Props = {
  children?: React.ReactNode
  tag?: React.ElementType
  href?: string
  rel?: string
  target?: string
  row?: boolean
  column?: boolean
  direction?: string | Array<string>
  center?: boolean
  jCenter?: boolean
  jStart?: boolean
  jEnd?: boolean
  jBetween?: boolean
  jAround?: boolean
  jNormal?: boolean
  justifyContent?: string | Array<string>
  aCenter?: boolean
  aStart?: boolean
  aEnd?: boolean
  aStretch?: boolean
  aNormal?: boolean
  alignItems?: string | Array<string>
  gap?: number | string | Array<number | string>
  flex?: number | Array<number>
  full?: boolean
  width?: number | string | Array<number | string>
  fullWidth?: boolean
  halfWidth?: boolean
  height?: number | string | Array<number | string>
  fullHeight?: boolean
  halfHeight?: boolean
  wrap?: boolean
  padding?: number | string | Array<number | string | Array<number>>
  paddingX?: number | string
  paddingY?: number | string
  paddingTop?: number | string
  paddingBottom?: number | string
  paddingRight?: number | string
  paddingLeft?: number | string
  scroll?: boolean
  absolute?: boolean
  dir?: string
  style?: React.CSSProperties
  className?: string
  id?: string
  onClick?: (event?: MouseEvent) => void
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void
}

const AppFlex: React.FC<Props> = (props) => {
  const Tag: React.ElementType = props.tag ?? 'div'

  const classes = [
    ...helper.init(props),
    helper.direction(props),
    helper.justify(props),
    helper.align(props),
    helper.gap(props),
    helper.flex(props),
    helper.padding(props),
    helper.widthClass(props),
    helper.heightClass(props),
    helper.wrap(props),
    helper.scroll(props),
    helper.absolute(props),
    props.className,
  ]

  const styles = {
    width: helper.width(props),
    height: helper.height(props),
    ...(props.style ?? {}),
  }

  const handleClick = (event: MouseEvent) => {
    if (props.onClick) {
      props.onClick(event)
    }
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (props.onScroll) {
      props.onScroll(event)
    }
  }

  return (
    <Tag href={props.href} rel={props.rel} target={props.target} dir={props.dir} id={props.id} className={cn(classes)} style={styles} onClick={handleClick} onScroll={handleScroll}>
      {props.children}
    </Tag>
  )
}

export default AppFlex