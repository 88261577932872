import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store'

import App from 'components/App'
import HomeWlistsItem from 'components/Home/HomeWlists/HomeWlistsItem'

const HomeWlists: React.FC = () => {
  const { t } = useTranslation()

  const wlists = useAppSelector(({ $wlist }) => $wlist.all)

  return wlists.length ? (
    <App.Flex column gap={4}>
      <App.Text bold>{t('Waiting list')}:</App.Text>
      
      {wlists.map((item, index) => (
        <HomeWlistsItem key={index} item={item} />
      ))}
    </App.Flex>
  ) : null
}

export default HomeWlists