import i18n from 'utils/i18n'

import store, { AppDispatch } from 'store'
import $app from 'store/app'
import $business from 'store/business'
import $resource from 'store/resource'
import $category from 'store/category'
import $service from 'store/service'

export const fetchCategories = async (dispatch: AppDispatch, phone: string) => {
  const result = await $category.api.all({ number: phone })
  if (result && result.status === 'success') {
    dispatch($category.set.all(result.data))
  } else {
    dispatch($app.set.error({ message: i18n.t(result?.message) }))
  }
}

export const fetchServices = async (dispatch: AppDispatch, phone: string) => {
  const result = await $service.api.all({ number: phone })
  if (result && result.status === 'success') {
    dispatch($service.set.all(result.data))
  } else {
    dispatch($app.set.error({ message: i18n.t(result?.message) }))
  }
}

export const fetchResources = async (dispatch: AppDispatch) => {
  const result = await $resource.api.all()
  if (result && result.status === 'success') {
    dispatch($resource.set.all(result.data))
  } else {
    dispatch($app.set.error({ message: i18n.t(result?.message) }))
  }
}

export const fetchWhat = async (dispatch: AppDispatch, phone: string) => {
  const state = store.getState()
  const settings = $business.get.settings(state)

  const calls = [fetchServices(dispatch, phone)]

  if (settings.display_category) {
    calls.push(fetchCategories(dispatch, phone))
  }

  if (settings.display_resource) {
    calls.push(fetchResources(dispatch))
  }

  const result = await Promise.all(calls)
  return result
}