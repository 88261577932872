import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import ar_AR from 'langs/ar_AR.json'
import hb_ISR from 'langs/hb_ISR.json'
import en_US from 'langs/en_US.json'
import ja_JP from 'langs/ja_JP.json'
import ru_RU from 'langs/ru_RU.json'

i18n.use(initReactI18next).init({
  resources: {
    ar_AR: {
      translation: ar_AR,
    },

    hb_ISR: {
      translation: hb_ISR,
    },

    en_US: {
      translation: en_US,
    },

    ja_JP: {
      translation: ja_JP,
    },

    ru_RU: {
      translation: ru_RU,
    },
  },

  lng: 'hb_ISR',
  fallbackLng: 'en_US',

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
