import moment from 'moment'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { request, businessCode } from './index'

export type DayType = {[date: string]: Array<string>}

type StateType = {
  all: Array<DayType>
  month: number,
  year: number,
  selectedDay: number | null
  selectedTime: string | null
}

const initialState: StateType = {
  all: [],
  month: Number(moment().format('M')),
  year: Number(moment().format('YYYY')),
  selectedDay: null,
  selectedTime: null,
}

export const daySlice = createSlice({
  name: '$day',

  initialState,

  reducers: {
    all: (state: StateType, action: PayloadAction<Array<DayType>>) => {
      state.all = action.payload
    },

    month: (state: StateType, action: PayloadAction<{month: number, year: number, day?: number, time?: string}>) => {
      state.month = action.payload.month
      state.year = action.payload.year
      state.selectedDay = action.payload?.day ?? null
      state.selectedTime = action.payload?.time ?? null
    },

    selectedDay: (state: StateType, action: PayloadAction<number | null>) => {
      state.selectedDay = action.payload
    },

    selectedTime: (state: StateType, action: PayloadAction<string | null>) => {
      state.selectedTime = action.payload
    },

    reset: (state: StateType) => {
      state.month = Number(moment().format('M'))
      state.year = Number(moment().format('YYYY'))
      state.selectedDay = null
      state.selectedTime = null
    },
  },
})

const get = {
  days: createSelector([
    state => state.$day.all,
  ], (days) => {
    const keys = Object.keys(days)
    return keys.map(key => Number(moment(key, 'YYYY-MM-DD').format('D')))
  }),

  times: createSelector([
    state => state.$day.all,
    state => state.$day.year,
    state => state.$day.month,
    state => state.$day.selectedDay,
  ], (days, year, month, day) => {
    return day && days ? (days[moment().year(year).month(month - 1).date(day).format('YYYY-MM-DD')] ?? []) : []
  }),

  formatTime: createSelector([
    state => state.$day.year,
    state => state.$day.month,
    state => state.$day.selectedDay,
    state => state.$day.selectedTime,
  ], (year, month, day, time) => {
    if (day && time) {
      const [hours, minutes] = time.split(':').map(Number)

      return moment().year(year).month(month - 1).date(day).set({
        hour: hours,
        minute: minutes,
      }).format('YYYY-MM-DD HH:mm')
    }

    return null
  }),
}

const api = {
  all: (params: object) => {
    return request(`days/id/${businessCode()}`, 'POST', params)
  },
}

const day = {
  reducer: daySlice.reducer,
  set: daySlice.actions,
  get,
  api,
}

export default day