import { useEffect, useRef } from 'react'

const useInterval = (callback: () => void, delay: number | null): void => {
  const savedCallback = useRef<() => void>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const func = () => {
      savedCallback.current && savedCallback.current()
    };
    if (delay !== null) {
      const id = setInterval(func, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export default useInterval