import values from 'utils/values'

import { Props } from './AppText'

import styles from './AppText.module.scss'

const helper = {
  init: (props: Props) => {
    return [styles.text]
  },

  size: (props: Props) => {
    const defaultValue = styles.sNormal
    const value = values.classWithPrefix(props.size, 's')
    return (value ? styles[value] : null)
      ?? (props.large ? styles.sLarge : null)
      ?? (props.small ? styles.sSmall : null)
      ?? (props.xl ? styles.sXl : null)
      ?? (props.xs ? styles.sXs : null)
      ?? defaultValue
  },

  weight: (props: Props) => {
    const defaultValue = styles.wNormal
    const value = values.classWithPrefix(props.weight, 'w')
    return (value ? styles[value] : null)
      ?? (props.bold ? styles.wBold : null)
      ?? (props.light ? styles.wLight : null)
      ?? (props.medium ? styles.wMedium : null)
      ?? (props.semiBold ? styles.wSemiBold : null)
      ?? (props.black ? styles.wBlack : null)
      ?? defaultValue
  },

  italic: (props: Props) => {
    const defaultValue = null
    const value = values.value(props.italic)
    return (value ? styles.italic : defaultValue)
  },

  align: (props: Props) => {
    const defaultValue = styles.left
    const value = values.class(props.align)
    return (value ? styles[value] : null)
      ?? (props.center ? styles.center : null)
      ?? (props.left ? styles.left : null)
      ?? (props.right ? styles.right : null)
      ?? (props.justify ? styles.justify : null)
      ?? defaultValue
  },

  colorClass: (props: Props) => {
    const defaultValue = styles.primary
    return (props.primary ? styles.primary : null)
      ?? (props.highlight ? styles.highlight : null)
      ?? (props.muted ? styles.muted : null)
      ?? (props.gray ? styles.gray : null)
      ?? (props.danger ? styles.danger : null)
      ?? (props.white ? styles.white : null)
      ?? defaultValue
  },

  color: (props: Props) => {
    const defaultValue = null
    const value = values.value(props.color)
    return (value ? value : defaultValue)
  },

  height: (props: Props) => {
    const defaultValue = styles.hNormal
    const value = values.value(props.height)
    return (value ? value : defaultValue)
  },

  transform: (props: Props) => {
    const defaultValue = styles.normal
    const value = values.class(props.transform)
    return (value ? styles[value] : null)
      ?? (props.uppercase ? styles.uppercase : null)
      ?? (props.lowercase ? styles.lowercase : null)
      ?? (props.capitalize ? styles.capitalize : null)
      ?? defaultValue
  },

  nowrap: (props: Props) => {
    const defaultValue = null
    const value = values.value(props.nowrap)
    return (value ? styles.nowrap : defaultValue)
  },
}

export default helper