import values from 'utils/values'

import { Props } from './AppIcon'

import styles from './AppIcon.module.scss'

const helper = {
  init: (props: Props) => {
    const result = [styles.icon]
    return result
  },

  size: (props: Props) => {
    const defaultValue = styles.normal
    const value = values.value(props.size) as number
    return (value ? styles[`s${value}`] : null)
      ??(props.large ? styles.large : null)
      ?? (props.small ? styles.small : null)
      ?? (props.xs ? styles.xs : null)
      ?? (props.xl ? styles.xl : null)
      ?? defaultValue
  },

  variant: (props: Props) => {
    const defaultValue = styles.primary
    const value = values.class(props.variant)
    return (value ? styles[value] : null)
      ?? (props.muted ? styles.muted : null)
      ?? (props.primary ? styles.primary : null)
      ?? (props.primaryColor ? styles.primaryColor : null)
      ?? (props.alternate ? styles.alternate : null)
      ?? (props.danger ? styles.danger : null)
      ?? (props.white ? styles.white : null)
      ?? defaultValue
  },

  color: (props: Props) => {
    const defaultValue = null
    const value = values.value(props.color) as string
    return value ?? defaultValue
  },

  rotate: (props: Props) => {
    const defaultValue = null
    return (props.rotate ? styles.rotate : null)
      ?? defaultValue
  },
}

export default helper