import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { useAppDispatch, useAppSelector } from 'store'
import $business from 'store/business'
import $day from 'store/day'
import $wlist from 'store/wlist'

import App from 'components/App'

import styles from './AppointmentCalendar.module.scss'
import AppointmentWlist from 'components/Appointment/AppointmentWlist'
import Helper from 'components/Helper'

export type Props = {
  onScroll?: (value: boolean) => void
}

const AppointmentCalendar: React.FC<Props> = ({ onScroll }) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const settings = useAppSelector($business.get.settings)
  const month = useAppSelector(({ $day }) => $day.month)
  const year = useAppSelector(({ $day }) => $day.year)
  const days = useAppSelector($day.get.days)
  const selectedDay = useAppSelector(({ $day }) => $day.selectedDay)
  const wlistEnabled = useAppSelector(({ $wlist }) => $wlist.enabled)

  const [calendarHeight, setCalendarHeight] = useState<number>(0)

  const calendarRef = useRef<HTMLDivElement>(null)
  
  useEffect(() => {
    if (calendarRef.current) {
      const calendarHeight = calendarRef.current.clientHeight
      setCalendarHeight(calendarHeight)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarRef.current])

  const handleDay = (day: number | null) => {
    dispatch($day.set.selectedDay(day))
    if (onScroll) {
      onScroll(day !== selectedDay)
    }
  }

  const handleMonth = (newMonth: number, newYear: number) => {
    dispatch($day.set.month({ month: newMonth, year: newYear }))

    if (onScroll) {
      onScroll(false)
    }
  }

  const handleWlistToggle = () => {
    dispatch($wlist.set.enabled(!wlistEnabled))
  }

  return (
    <App.Flex column gap={4}>
      <App.Flex row aCenter jBetween gap={2}>
        <App.Text bold>{t('Select date')}:</App.Text>

        {settings.display_waiting_list ? (
          <App.Button outlined={!wlistEnabled} small icon="chevron-down" iconAppend iconRotate={wlistEnabled} onClick={handleWlistToggle}>{t('Waiting list')}</App.Button>
        ) : null}
      </App.Flex>

      <App.Flex column gap={4} height={calendarHeight} className={cn(styles.wlist, {[styles.expand]: wlistEnabled})}>
        <AppointmentWlist />
      </App.Flex>

      <div ref={calendarRef} className={cn(styles.calendar, {[styles.expand]: !wlistEnabled})}>
        <Helper.Calendar
          month={month}
          year={year}
          activeDays={days}
          selectedDay={selectedDay}
          onMonthChange={handleMonth}
          onDayChange={handleDay}
        />
      </div>
    </App.Flex>
  )
}

export default AppointmentCalendar