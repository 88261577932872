import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'store'
import $app from 'store/app'
import $wlist from 'store/wlist'

import App from 'components/App'

import styles from './DialogCancelWlistConfirm.module.scss'

const DialogCancelWlistConfirm: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const cancelId = useAppSelector(({ $wlist }) => $wlist.cancelId)

  const [loading, setLoading] = useState(false)

  const handleCancelDialogClose = () => {
    dispatch($wlist.set.cancelId(null))
  }

  const handleCancel = async () => {
    setLoading(true)

    const result = await $wlist.api.cancel({ list_id: cancelId })
    if (result && result.status === 'success') {
      dispatch($wlist.set.cancelId(null))
      dispatch($wlist.set.fetch(true))
    } else {
      dispatch($app.set.error({
        message: t('Please check all input data and the selected time and date to ensure everything is correct and try again.'),
        button: t('Try again'),
      }))
    }

    setLoading(false)
  }

  return (
    <App.Dialog open={cancelId != null} onClose={handleCancelDialogClose}>
      <App.Flex column aCenter gap={6} className={styles.container}>
        <App.Flex center className={styles.icon}>
          <App.Icon icon="error" xl />
        </App.Flex>

        <App.Text center large bold>{t('Cancel a waiting list')}</App.Text>

        <App.Text center muted>{t('Are you sure you want to cancel this waiting list?')}</App.Text>

        <App.Flex row fullWidth aCenter gap={4}>
          <App.Button flex={1} outlined onClick={handleCancelDialogClose}>{t('Close')}</App.Button>
          <App.Button flex={1} loading={loading} onClick={handleCancel}>{t('Confirm')}</App.Button>
        </App.Flex>
      </App.Flex>
    </App.Dialog>
  )
}

export default DialogCancelWlistConfirm