import { isMobile } from 'react-device-detect'

const values = {
  class: (value: string | Array<string> | undefined): string | null => {
    return values.value(value) as string
  },

  classWithPrefix: (value: string | number | Array<string | number> | undefined, prefix: string): string | null => {
    let result = values.value(value)
    if (result) { 
      if (typeof result == 'string') {
        if (!result.includes(prefix)) {
          return null
        }
      }

      if (typeof result == 'number') {
        result = prefix + result
      }
    }

    return result as string
  },

  px: (value: number | string | Array<number | string> | undefined): string | null => {
    let result = values.value(value)
    if (result) {
      if (typeof result == 'number') {
        result = result + 'px'
      }
    }

    return result as string
  },

  padding: (value: number | string | Array<number | string | Array<number | string>>): Array<number> => {
    let result = value
    if (result || result === 0) {
      if (typeof result == 'string') {
        result = result.split(' ')
      }

      if (typeof result == 'number') {
        result = [result]
      }
    }

    return result as Array<number>
  },

  value: (value: number | string | boolean | Array<number | string | boolean | Array<number | string>> | undefined): number | string | boolean | Array<number | string> | null => {
    if (!value) {
      return null
    }

    let result: number | string | boolean | Array<number | string>
    if (Array.isArray(value)) {
      const [desktop, mobile] = value
      result = isMobile ? mobile : desktop
    } else {
      result = value
    }

    return result
  },
}

export default values