import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { useAppDispatch, useAppSelector } from 'store'
import $business from 'store/business'
import $customer from 'store/customer'
import $service from 'store/service'
import $resource from 'store/resource'
import $wlist from 'store/wlist'

import App from 'components/App'

import styles from './AppointmentWlistSuccess.module.scss'

export type Props = {
  onClose: () => void
}

const AppointmentWlistSuccess: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const settings = useAppSelector($business.get.settings)
  const service = useAppSelector($service.get.selected)
  const resource = useAppSelector($resource.get.selected)
  const formatFrom = useAppSelector($wlist.get.formatTime('from'))
  const formatTo = useAppSelector($wlist.get.formatTime('to'))
  const temp = useAppSelector(({ $customer }) => $customer.temp)

  useEffect(() => {
    if (temp.phone !== '' && temp.name !== '') {
      localStorage.setItem('customerTemp', JSON.stringify(temp))
      dispatch($customer.set.loggedIn(true))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp])

  return  (
    <App.Flex column aCenter gap={6} className={styles.container}>
      <App.Flex center className={styles.icon}>
        <App.Icon icon="check" xl primary />
      </App.Flex>

      <App.Text center large bold>{t('Successfully added to waiting list')}</App.Text>

      <App.Text center muted>{t('Your requested date and time have been successfully added to the waiting list. We will notify you as soon as a slot becomes available. Thank you for your patience.')}</App.Text>

      <App.Flex column fullWidth gap={2} className={styles.details}>
        <App.Text bold>{service.name}</App.Text>

        <App.Flex column gap={1}>
          <App.Text xs gray>{t('Details')}:</App.Text>

          <App.Flex row wrap gap={2}>
            <App.Tag icon="clock">{moment(formatFrom).format('D')} {t(moment(formatFrom).format('MMMM'))}, {moment(formatFrom).format('HH:mm')} - {moment(formatTo).format('HH:mm')}</App.Tag>
            {resource?.id ? <App.Tag icon="user">{resource.name}</App.Tag> : null}
            {settings.display_price ? <App.Tag>₪{service.price}</App.Tag> : null}
          </App.Flex>
        </App.Flex>
      </App.Flex>

      <App.Button fullWidth onClick={onClose}>{t('Got it!')}</App.Button>
    </App.Flex>
  )
}

export default AppointmentWlistSuccess