import values from 'utils/values'

import { Props } from './AppButton'

import global from 'scss/global.module.scss'
import styles from './AppButton.module.scss'

const helper = {
  init: (props: Props) => {
    const result = [styles.button]
    if (props.loading) {
      result.push(styles.loading)
    }
    return result
  },

  variant: (props: Props) => {
    const defaultValue = styles.primary
    const value = values.class(props.variant)
    return (value ? styles[value] : null)
      ?? (props.primary ? styles.primary : null)
      ?? defaultValue
  },

  disabled: (props: Props) => {
    const defaultValue = null
    const value = values.value(props.disabled)
    return (value ? styles.disabled : defaultValue)
  },

  outlined: (props: Props) => {
    const defaultValue = null
    const value = values.value(props.outlined)
    return (value ? styles.outlined : defaultValue)
  },

  size: (props: Props) => {
    const defaultValue = null
    const value = values.class(props.size)
    return (value ? styles[value] : null)
      ?? (props.large ? styles.large : null)
      ?? (props.small ? styles.small : null)
      ?? defaultValue
  },

  flex: (props: Props) => {
    const defaultValue = null
    const value = values.classWithPrefix(props.flex, 'f')
    return (value ? global[value] : defaultValue)
  },

  widthClass: (props: Props) => {
    const defaultValue =  null
    return (props.fullWidth ? global.wFull : null)
      ?? (props.halfWidth ? global.wHalf : null)
      ?? defaultValue
  },

  width: (props: Props) => {
    const defaultValue = null
    const value = values.px(props.width)
    return value ?? defaultValue
  },
}

export default helper