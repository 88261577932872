import { useTranslation } from 'react-i18next'

import App from 'components/App'

import styles from './DialogNotAllowBooking.module.scss'

export type Props = {
  open: boolean
  onClose: () => void
}

const DialogNotAllowBooking: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation()

  return (
    <App.Dialog open={open} onClose={onClose}>
      <App.Flex column gap={6} className={styles.container}>
        <App.Flex column gap={3} aCenter>
          <App.Text center large bold>{t('Appointment Unavailable')}</App.Text>
          <App.Text center muted>{t('We regret to inform you that we are currently unable to process appointments at this time. We apologize for any inconvenience this may cause.')}</App.Text>
        </App.Flex>

        <App.Button onClick={onClose}>{t('Got it')}</App.Button>
      </App.Flex>
    </App.Dialog>
  )
}

export default DialogNotAllowBooking