import { useEffect, useState } from 'react'

import { fetchWhat } from 'utils/api'

import { useAppDispatch, useAppSelector } from 'store'
import $business from 'store/business'
import $customer from 'store/customer'
import $resource from 'store/resource'

import App from 'components/App'
import AppointmentCategories from 'components/Appointment/AppointmentCategories'
import AppointmentServices from 'components/Appointment/AppointmentServices'
import AppointmentResources from 'components/Appointment/AppointmentResources'

import styles from './AppointmentWhat.module.scss'

export type Props = {
  onScrollDown?: (value: boolean) => void
}

const AppointmentWhat: React.FC<Props> = ({ onScrollDown }) => {
  const dispatch = useAppDispatch()
  const settings = useAppSelector($business.get.settings)
  const phone = useAppSelector($customer.get.phone)
  const resources = useAppSelector($resource.get.filtered)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    setLoading(true)

    await fetchWhat(dispatch, phone)

    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  const handleScrollDown = (value: boolean) => {
    if (onScrollDown && settings.display_resource && resources.length) {
      onScrollDown(value)
    }
  }

  return loading ? (
    <App.Flex center height={343}>
      <App.Loader xl />
    </App.Flex>
  ) : (
    <App.Flex column gap={6} className={styles.content}>
      <AppointmentCategories />
      <AppointmentServices onScroll={handleScrollDown} />
      <AppointmentResources />
    </App.Flex>
  )
}

export default AppointmentWhat