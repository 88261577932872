import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import cn from 'classnames'

import AppFlex from 'components/App/AppFlex'
import AppText from 'components/App/AppText'
import AppLoader from 'components/App/AppLoader'

import helper from './helper'
import { useAppSelector } from 'store'

export type Props = {
  value: string | number
  type?: string
  placeholder?: string
  error?: string
  danger?: boolean
  small?: boolean
  large?: boolean
  size?: string | Array<string>
  full?: boolean
  width?: string | number | Array<string | number>
  fullWidth?: boolean
  halfWidth?: boolean
  height?: string | number | Array<string | number>
  fullHeight?: boolean
  halfHeight?: boolean
  flex?: number | Array<number>
  readonly?: boolean
  align?: string | Array<string>
  left?: boolean
  center?: boolean
  right?: boolean
  disabled?: boolean
  limit?: number
  prefix?: string
  sufix?: string
  loading?: boolean
  style?: React.CSSProperties
  containerStyle?: React.CSSProperties
  onChange: (value: string) => void
  onEnter?: () => void
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void
}

const AppInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const isRtl = useAppSelector(({ $app }) => $app.isRtl)

  const [active, setActive] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const classes = [
    ...helper.init(props),
    helper.align(props),
  ]

  const styles = {
    ...(props.style ?? {}),
  }

  const containerProps = {
    full: props.full,
    width: props.width,
    fullWidth: props.fullWidth ?? true,
    halfWidth: props.halfWidth,
    height: props.height,
    fullHeight: props.fullHeight,
    halfHeight: props.halfHeight,
    flex: props.flex,
  }

  const wrapperClasses = [
    ...helper.initWrapper(props),
    helper.active(active),
    helper.disabled(props),
    helper.error(props),
  ]

  useImperativeHandle(ref, () => inputRef.current!, [])

  useEffect(() => {
    const input = inputRef?.current
    if (input) {
      const handleWheel = (event: WheelEvent) => {
        event.preventDefault()
      }
  
      input.addEventListener('wheel', handleWheel, { passive: false })
      return () => {
        input.removeEventListener('wheel', handleWheel)
      }
    }
  }, [])

  useEffect(() => {
    setActive(props.value !== '')
  }, [props.value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.currentTarget.value

    if (props.limit && value.length > props.limit) {
      value = value.slice(-props.limit)
    }
    
    if (props.onChange && !props.disabled && !props.readonly) {
      props.onChange(value)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    let value = event.currentTarget.value

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault()
    }

    if (event.key === 'Backspace' && value === '') {
      event.preventDefault()
      if (props.onChange && !props.disabled && !props.readonly) {
        props.onChange(value)
      }
    }

    if (event.key === 'Enter') {
      if (props.onEnter && !props.disabled) {
        props.onEnter()
      }
    }
  }

  const handleFocus = () => {
    setActive(true)
  }

  const handleBlur = () => {
    setActive(props.value !== '')
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    if (props.onPaste) {
      props.onPaste(event)
    }
  }

  return (
    <AppFlex column gap={1} style={props.containerStyle} {...containerProps}>
      <AppFlex aCenter className={cn(wrapperClasses)} dir={isRtl && props.type === 'tel' ? 'ltr' : undefined}>
        {props.prefix ? (
          <AppFlex aCenter paddingLeft={4}>
            <AppText muted>{props.prefix}</AppText>
          </AppFlex>
        ) : null}

        <AppFlex fullHeight flex={1}>
          <input
            ref={inputRef}
            value={props.value}
            type={props.type ?? 'text'}
            pattern={props.type === 'number' ? '[0-9]*' : undefined}
            placeholder={props.placeholder}
            disabled={props.disabled}
            readOnly={props.readonly}
            className={cn(classes)}
            style={styles}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onPaste={handlePaste}
          />
        </AppFlex>

        {props.loading ? (
          <AppFlex tag="span" aCenter>
            <AppLoader highlight />
          </AppFlex>
        ) : null}
      </AppFlex>

      {props.error && props.error !== '' ? (
        <AppFlex>
          <AppText small danger>{props.error}</AppText>
        </AppFlex>
      ) : null}
    </AppFlex>
  )
})

export default AppInput