import { useEffect, useState } from 'react'
import cn from 'classnames'

import styles from './TheLoader.module.scss'

export type Props = {
  loading: boolean
}

const Loader: React.FC<Props> = ({ loading }) => {
  const [removeLoader, setRemoveLoader] = useState(false)

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setRemoveLoader(true)
      }, 500)
    }
  }, [loading])

  return !removeLoader ? (
    <div className={cn(styles['ibox-content'], {[styles['sk-loading']]: loading})}>
      <div className={cn(styles['sk-spinner'], styles['sk-spinner-fading-circle'])}>
        <div className={cn(styles['sk-circle1'], styles['sk-circle'])}></div>
        <div className={cn(styles['sk-circle2'], styles['sk-circle'])}></div>
        <div className={cn(styles['sk-circle3'], styles['sk-circle'])}></div>
        <div className={cn(styles['sk-circle4'], styles['sk-circle'])}></div>
        <div className={cn(styles['sk-circle5'], styles['sk-circle'])}></div>
        <div className={cn(styles['sk-circle6'], styles['sk-circle'])}></div>
        <div className={cn(styles['sk-circle7'], styles['sk-circle'])}></div>
        <div className={cn(styles['sk-circle8'], styles['sk-circle'])}></div>
        <div className={cn(styles['sk-circle9'], styles['sk-circle'])}></div>
        <div className={cn(styles['sk-circle10'], styles['sk-circle'])}></div>
        <div className={cn(styles['sk-circle11'], styles['sk-circle'])}></div>
        <div className={cn(styles['sk-circle12'], styles['sk-circle'])}></div>
      </div>
    </div>
  ) : null
}

export default Loader