import { Route, Routes } from 'react-router-dom'

import Blank from 'components/Blank'
import HomePage from 'components/Home/HomePage'

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Blank />} />
      <Route path="/:code" element={<HomePage />} />
    </Routes>
  )
}

export default App
