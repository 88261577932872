import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import cn from 'classnames'

import AppFlex from 'components/App/AppFlex'
import AppText from 'components/App/AppText'
import AppIcon from 'components/App/AppIcon'

import helper from './helper'

export type Props = {
  value: string | number
  options: Array<{ value: string | number, label: string }>
  placeholder?: string
  error?: string
  danger?: boolean
  small?: boolean
  large?: boolean
  size?: string | Array<string>
  full?: boolean
  width?: string | number | Array<string | number>
  fullWidth?: boolean
  halfWidth?: boolean
  height?: string | number | Array<string | number>
  fullHeight?: boolean
  halfHeight?: boolean
  flex?: number | Array<number>
  align?: string | Array<string>
  left?: boolean
  center?: boolean
  right?: boolean
  disabled?: boolean
  loading?: boolean
  style?: React.CSSProperties
  containerStyle?: React.CSSProperties
  onChange: (value: string) => void
  onEnter?: () => void
}

const AppSelect = forwardRef<HTMLSelectElement, Props>((props, ref) => {
  const [active, setActive] = useState(false)

  const selectRef = useRef<HTMLSelectElement>(null)

  const classes = [
    ...helper.init(props),
    helper.align(props),
  ]

  const styles = {
    ...(props.style ?? {}),
  }

  const containerProps = {
    full: props.full,
    width: props.width,
    fullWidth: props.fullWidth ?? true,
    halfWidth: props.halfWidth,
    height: props.height,
    fullHeight: props.fullHeight,
    halfHeight: props.halfHeight,
    flex: props.flex,
  }

  const wrapperClasses = [
    ...helper.initWrapper(props),
    helper.active(active),
    helper.disabled(props),
    helper.error(props),
  ]

  useImperativeHandle(ref, () => selectRef.current!, [])

  useEffect(() => {
    setActive(props.value !== '')
  }, [props.value])

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let value = event.currentTarget.value

    if (props.onChange && !props.disabled) {
      props.onChange(value)
    }
  }

  return (
    <AppFlex column gap={1} style={props.containerStyle} {...containerProps}>
      <AppFlex aCenter className={cn(wrapperClasses)}>
        <AppFlex fullHeight flex={1} style={{ position: 'relative', zIndex: 1 }}>
          <select
            ref={selectRef}
            value={props.value}
            disabled={props.disabled}
            className={cn(classes)}
            style={styles}
            onChange={handleChange}
          >
            {props.placeholder ? (
              <option value="">{props.placeholder}</option>
            ) : null}

            {props.options.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
          </select>
        </AppFlex>
        
        <AppIcon icon="chevron-down" small />
      </AppFlex>

      {props.error && props.error !== '' ? (
        <AppFlex>
          <AppText small danger>{props.error}</AppText>
        </AppFlex>
      ) : null}
    </AppFlex>
  )
})

export default AppSelect