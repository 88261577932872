import { useAppDispatch, useAppSelector } from 'store'
import $app from 'store/app'

import App from 'components/App'

import styles from './DialogError.module.scss'

const DialogError: React.FC = () => {
  const dispatch = useAppDispatch()
  const error = useAppSelector($app.get.error)

  const handleClose = () => {
    dispatch($app.set.error(null))
  }

  return  (
    <App.Dialog open={error != null} onClose={handleClose}>
      <App.Flex column aCenter gap={6} className={styles.container}>
        <App.Flex center className={styles.icon}>
          <App.Icon icon="error" xl danger />
        </App.Flex>

        <App.Text center large bold>{error?.title}</App.Text>

        <App.Text center muted>{error?.message}</App.Text>

        <App.Button fullWidth variant="danger" onClick={handleClose}>{error?.button}</App.Button>
      </App.Flex>
    </App.Dialog>
  )
}

export default DialogError