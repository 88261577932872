declare global {
  interface WindowEventMap {
    'beforeinstallprompt': BeforeInstallPromptEvent;
  }
}

let deferredPrompt: BeforeInstallPromptEvent | null = null;

// Custom event interface for the beforeinstallprompt event
interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

class PWA {
  deferredPrompt: BeforeInstallPromptEvent | null

  constructor() {
    this.deferredPrompt = null
  }

  manifest = (manifest: {[key: string]: any}) => {
    const blob = new Blob([JSON.stringify(manifest)], { type: 'application/json' })
    const url = URL.createObjectURL(blob)

    const manifestLink = document.querySelector('link[rel="manifest"]')
    if (manifestLink) {
      manifestLink.setAttribute('href', url)
    }

    const iconLink = document.querySelector('link[rel="apple-touch-icon"]')
    if (iconLink) {
      iconLink.setAttribute('href', manifest.icons[0].src)
    }
  }

  events = () => {
    console.log('Events')
    window.addEventListener('beforeinstallprompt', (event: BeforeInstallPromptEvent) => {
      event.preventDefault()
      this.deferredPrompt = event

      console.log('Beforeinstallprompt')
    })

    window.addEventListener('appinstalled', (event) => {
      console.log('PWA was installed')
    })
  }

  prompt = () => {
    if (this.deferredPrompt) {
      // Show the install prompt in response to a user gesture
      this.deferredPrompt.prompt().then(() => {
        // Wait for the user to respond to the prompt
        deferredPrompt?.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
          } else {
            console.log('User dismissed the install prompt');
          }
          // Clear the deferredPrompt variable
          this.deferredPrompt = null;
        });
      });
    }
  }
}

const PWAInstance = new PWA()
export default PWAInstance