import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import cn from 'classnames'

import Theme from 'utils/Theme'
import PWA from 'utils/PWA'

import { useAppDispatch, useAppSelector } from 'store'
import $app from 'store/app'
import $business, { BusinessType } from 'store/business'
import $customer from 'store/customer'
import $service from 'store/service'
import $resource from 'store/resource'
import $day from 'store/day'
import $wlist from 'store/wlist'

import App from 'components/App'
import HomeContent from 'components/Home/HomeContent'
import TheLoader from 'components/TheLoader'

import styles from './HomePage.module.scss'

const HomePage: React.FC = () => {
  const { t, i18n } = useTranslation()

  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const wlistId = queryParams.get('w')

  const dispatch = useAppDispatch()
  const isRtl = useAppSelector(({ $app }) => $app.isRtl)
  const showContent = useAppSelector(({ $app }) => $app.showContent)
  const design = useAppSelector($business.get.design)
  const manifest = useAppSelector($business.get.manifest)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (manifest?.name) {
      PWA.manifest(manifest)
      // PWA.events()
    }
  }, [manifest])

  useEffect(() => {
    if (params?.code) {
      fetchBusiness(params.code)
    }

    dispatch($app.set.whitelist())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.code])

  useEffect(() => {
    if (design.loaded) {
      Theme.init(design.color)

      if (wlistId) {
        fetchWlist()
      } else {
        dispatch($app.set.showContent(true))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [design.loaded])

  useEffect(() => {
    if (showContent) {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }, [showContent])

  const fetchBusiness = async (code: string) => {
    const result = await $business.api.get(code)
    if (result && result.status === 'success') {
      const data = result.data as BusinessType
      dispatch($business.set.code(code))
      dispatch($business.set.data(data))
      dispatch($app.set.isRtl(data.language_code === 'hb_ISR' || data.language_code === 'ar_AR'))

      const [lang] = data.language_code.split('_')
      const htmlLang = lang === 'hb' ? 'he' : lang
      document.documentElement.lang = htmlLang

      if (data?.language_code) {
        i18n.changeLanguage(data.language_code)
      }
    } else {
      dispatch($app.set.error({ message: t(result?.message) }))
    }
  }

  const fetchWlist = async () => {
    const result = await $wlist.api.get({ waiting_id: wlistId })
    if (result && result.status === 'success' && result.data) {
      localStorage.setItem('customerPhone', result.data.phone)

      dispatch($wlist.set.id(wlistId))

      dispatch($customer.set.temp({
        phone: result.data.phone,
        name: result.data.name,
      }))

      dispatch($service.set.selectedId(result.data.item_id))
      dispatch($resource.set.selectedId(result.data.resource_id))

      const date = moment(result.data.from)
      dispatch($day.set.month({
        month: date.month() + 1,
        year: date.year(),
        day: date.date(),
      }))
    } else {
      dispatch($app.set.error({
        title: t('The link is invalid'),
        message: t('It looks like the link you used to access your booking is either expired or invalid. Please check the date and time of your booking request. If you believe this is an error, or if you need further assistance, please contact our support team. Thank you for your understanding.'),
      }))
    }

    queryParams.delete('w')
    navigate({ search: queryParams.toString() }, { replace: true })

    dispatch($app.set.showContent(true))
  }

  return (
    <App.Flex column center style={{ backgroundImage: `url(${design.background})` }} className={cn(styles.container, {[styles.rtl]: isRtl})}>
      <App.Flex id="modal-root" column className={styles.card}>
        {showContent ? (
          <HomeContent />
        ) : (
          <App.Flex column full center>
            {/* <App.Text xl bold highlight>404</App.Text>
            <App.Text highlight>{t('Page not found')}</App.Text> */}
          </App.Flex>
        )}
      </App.Flex>

      <TheLoader loading={loading} />
    </App.Flex>
  )
}

export default HomePage