import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'store'
import $category, { CategoryType } from 'store/category'

import App from 'components/App'

import styles from './AppointmentCategories.module.scss'

const AppointmentCategories: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const categories = useAppSelector($category.get.sorted)
  const selectedIds = useAppSelector(({ $category }) => $category.selectedIds)

  const handleToggle = (id: number | string) => {
    const numberId = Number(id)
    const temp = [...selectedIds]
    if (temp.includes(numberId)) {
      temp.splice(selectedIds.indexOf(numberId), 1)
    } else {
      temp.push(numberId)
    }

    dispatch($category.set.selectedIds(temp))
  }

  return categories.length ? (
    <App.Flex column gap={4}>
      <App.Text bold height={1}>{t('Select category')}:</App.Text>

      <App.Flex row gap={2} className={styles.scroll}>
        {categories.map((item: CategoryType) => (
          <App.Badge key={item.id} active={selectedIds.includes(item.id)} value={item.id} onClick={handleToggle}>
            {item.name}
          </App.Badge>
        ))}
      </App.Flex>
    </App.Flex>
  ) : null
}

export default AppointmentCategories