import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { ServiceType } from './service'
import { request, businessCode } from './index'

export type CategoryType = {
  id: number
  name: string
  norders: number
}

type StateType = {
  all: Array<CategoryType>
  selectedIds: Array<number>
}

const initialState: StateType = {
  all: [],
  selectedIds: [],
}

export const categorySlice = createSlice({
  name: '$category',

  initialState,

  reducers: {
    all: (state: StateType, action: PayloadAction<Array<CategoryType>>) => {
      state.all = action.payload
    },

    selectedIds: (state: StateType, action: PayloadAction<Array<number>>) => {
      state.selectedIds = action.payload
    },

    reset: (state: StateType) => {
      state.selectedIds = []
    },
  },
})

const get = {
  sorted: createSelector([
    state => state.$service.all,
    state => state.$category.all,
  ], (services, categories) => {
    const filtered = categories.filter((item: CategoryType) => services.some((service: ServiceType) => service.categories === item.id))
    const withOrders = filtered.map((item: CategoryType) => {
      const norders = services.filter((service: ServiceType) => service.categories === item.id).reduce((acc: number, service: ServiceType) => {
        return acc + service.norders
      }, 0)

      return {
        ...item,
        norders,
      }
    })

    withOrders.sort((a: CategoryType, b: CategoryType) => {
      return b.norders - a.norders
    })

    return withOrders
  }),
}

const api = {
  all: (params: object) => {
    return request(`categories/id/${businessCode()}`, 'POST', params)
  },
}

const category = {
  reducer: categorySlice.reducer,
  set: categorySlice.actions,
  get,
  api,
}

export default category