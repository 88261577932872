import { Props } from './AppCard'

import styles from './AppCard.module.scss'

const helper = {
  init: (props: Props) => {
    const classes = [styles.card]

    if (props.active) {
      classes.push(styles.active)
    }

    if (props.onClick) {
      classes.push(styles.clickable)
    }
    return classes
  },

  size: (props: Props) => {
    const defaultValue = null
    return (props.large ? styles.large : null)
      ?? (props.small ? styles.small : null)
      ?? (props.xl ? styles.xl : null)
      ?? (props.xs ? styles.xs : null)
      ?? defaultValue
  },

  disabled: (props: Props) => {
    const defaultValue = null
    return (props.disabled ? styles.disabled : null)
      ?? defaultValue
  },
}

export default helper