class Theme {
  colors = {
    primary: '#275A7B',
    hover: '#1E76AE',
    active: '#1489D4',
    disabled: '#A7BCCA',
    light: '#EBF3F8',
    bg: '#D6E5EF',
    shadow: 'rgba(39, 90, 123, 0.4)',
  }

  get = () => {
    return this.colors
  }

  init = (color: string) => {
    if (color) {
      this.colors = {
        primary: color,
        hover: this.opacityColor(color, 0.8), //this.changeColor(color, { r: -9, g: 28, b: 51 }),
        active: this.opacityColor(color, 1), //this.changeColor(color, { r: -19, g: 47, b: 89 }),
        disabled: this.opacityColor(color, 0.5), //this.changeColor(color, { r: 128, g: 98, b: 79 }),
        light: this.opacityColor(color, 0.1), //this.changeColor(color, { r: 196, g: 153, b: 125 }),
        bg: this.opacityColor(color, 0.2), //this.changeColor(color, { r: 175, g: 139, b: 116 }),
        shadow: this.opacityColor(color, 0.3),
      }
    }

    this.setVars()
  }

  changeColor = (color: string, offset: {r: number, g: number, b: number}) => {
    let { r, g, b } = this.hexToRgb(color)

    r = Math.min(255, Math.max(0, r + offset.r))
    g = Math.min(255, Math.max(0, g + offset.g))
    b = Math.min(255, Math.max(0, b + offset.b))

    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  }

  opacityColor = (color: string, opacity: number) => {
    let { r, g, b } = this.hexToRgb(color)
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  }

  lightenColor = (color: string, percent: number) => {
    let { r, g, b } = this.hexToRgb(color)
    r = Math.min(255, Math.floor(r + (255 - r) * percent / 100))
    g = Math.min(255, Math.floor(g + (255 - g) * percent / 100))
    b = Math.min(255, Math.floor(b + (255 - b) * percent / 100))
    
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  }

  hexToRgb = (color: string) => {
    const hex = color.replace(/^#/, '')
    let bigint = parseInt(hex, 16)
    let r = (bigint >> 16) & 255
    let g = (bigint >> 8) & 255
    let b = bigint & 255

    return { r, g, b }
  }

  setVars = () => {
    const root = document.documentElement

    root.style.setProperty('--theme-primary-color', this.colors.primary)
    root.style.setProperty('--theme-hover-color', this.colors.hover)
    root.style.setProperty('--theme-active-color', this.colors.active)
    root.style.setProperty('--theme-disabled-color', this.colors.disabled)
    root.style.setProperty('--theme-light-color', this.colors.light)
    root.style.setProperty('--theme-bg-color', this.colors.bg)
    root.style.setProperty('--theme-shadow-color', this.colors.shadow)
  }
}

const ThemeInstance = new Theme()
export default ThemeInstance