import React from 'react'

import cn from 'classnames'

import helper from './helper'

export type Props = {
  children?: React.ReactNode
  tag?: React.ElementType
  href?: string
  rel?: string
  target?: string
  inline?: boolean
  size?: number | string | Array<number | string>
  large?: boolean
  small?: boolean
  xl?: boolean
  xs?: boolean
  weight?: number | string | Array<number | string>
  bold?: boolean
  light?: boolean
  medium?: boolean
  semiBold?: boolean
  black?: boolean
  italic?: boolean | Array<boolean>
  align?: string | Array<string>
  left?: boolean
  center?: boolean
  right?: boolean
  justify?: boolean
  color?: string | Array<string>
  primary?: boolean
  highlight?: boolean
  muted?: boolean
  gray?: boolean
  danger?: boolean
  white?: boolean
  height?: number
  uppercase?: boolean
  lowercase?: boolean
  capitalize?: boolean
  transform?: string | Array<string>
  nowrap?: boolean | Array<boolean>
  style?: React.CSSProperties
  onClick?: (event?: MouseEvent) => void
}

const AppText: React.FC<Props> = (props) => {
  const Tag: React.ElementType = props.tag ?? (props.inline ? 'span' : 'p')

  const classes = [
    ...helper.init(props),
    helper.size(props),
    helper.weight(props),
    helper.italic(props),
    helper.align(props),
    helper.colorClass(props),
    helper.transform(props),
    helper.nowrap(props),
  ]

  const styles = {
    color: helper.color(props),
    lineHeight: helper.height(props),
    ...(props.style ?? {}),
  }

  const handleClick = (event: MouseEvent) => {
    if (props.onClick) {
      props.onClick(event)
    }
  }

  return (
    <Tag href={props.href} rel={props.rel} target={props.target} className={cn(classes)} style={styles} onClick={handleClick}>{props.children}</Tag>
  )
}

export default AppText