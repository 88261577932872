import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store'

import App from 'components/App'
import HomeReservationsItem from 'components/Home/HomeReservations/HomeReservationsItem'

const HomeReservations: React.FC = () => {
  const { t } = useTranslation()
  
  const orders = useAppSelector(({ $order }) => $order.all)

  return orders.length ? (
    <App.Flex column gap={4}>
      <App.Text bold>{t('Your reservations')}:</App.Text>
      
      {orders.map((item, index) => (
        <HomeReservationsItem key={index} item={item} />
      ))}
    </App.Flex>
  ) : null
}

export default HomeReservations