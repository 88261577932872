import { Props } from './AppTag'

import styles from './AppTag.module.scss'

const helper = {
  init: (props: Props) => {
    const classes = [styles.tag]
    return classes
  },

  size: (props: Props) => {
    const defaultValue = null
    return (props.large ? styles.large : null)
      ?? (props.small ? styles.small : null)
      ?? (props.xl ? styles.xl : null)
      ?? (props.xs ? styles.xs : null)
      ?? defaultValue
  },
}

export default helper