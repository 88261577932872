import { useTranslation } from 'react-i18next'

import App from 'components/App'

type FallbackProps = {
  error: Error
  componentStack: string
  eventId: string
  resetError: () => void
}

const TheFallback: React.FC<FallbackProps> = ({ resetError }) => {
  const { t } = useTranslation()

  return (
    <App.Flex column full center gap={6}>
      <App.Flex column center fullWidth>
        <App.Text center highlight size={32} weight={600} height={1}>{t('Oops!')}</App.Text>
        <App.Text center highlight size={24} weight={600} height={1}>{t('Something went wrong')}</App.Text>
      </App.Flex>

      <App.Text center>{t('An unexpected error has occurred. Our team has been notified, and weʼre working to fix the issue')}</App.Text>
      
      <App.Button primary onClick={resetError}>{t('Try again')}</App.Button>
    </App.Flex>
  )
}

export default TheFallback