import { useTranslation } from 'react-i18next' 

import { useAppDispatch, useAppSelector } from 'store'
import $wlist from 'store/wlist'

import App from 'components/App'

import styles from './AppointmentWlist.module.scss'

const AppointmentWlist: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const selectedDate = useAppSelector($wlist.get.selectedDate)
  const selectedFrom = useAppSelector(({ $wlist }) => $wlist.selectedFrom)
  const selectedTo = useAppSelector(({ $wlist }) => $wlist.selectedTo)

  const handleDialog = (dialog: string | null) => () => {
    dispatch($wlist.set.dialog(dialog))
  }

  return (
    <App.Flex column aCenter gap={4} className={styles.container}>
      <App.Button outlined={!selectedDate} fullWidth icon="calendar" onClick={handleDialog('calendar')}>{selectedDate ?? t('Select date')}</App.Button>
      <App.Flex row fullWidth aCenter gap={4}>
        <App.Button outlined={!selectedFrom} flex={1} icon="clock" onClick={handleDialog('from')}>{selectedFrom ? `${t('from')} ${selectedFrom}` : t('Time from')}</App.Button>
        <App.Button outlined={!selectedTo} flex={1} icon="clock" onClick={handleDialog('to')}>{selectedTo ? `${t('to')} ${selectedTo}` : t('Time to')}</App.Button>
      </App.Flex>
    </App.Flex>
  )
}

export default AppointmentWlist