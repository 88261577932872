import React from 'react'

import cn from 'classnames'

import helper from './helper'

export type Props = {
  children?: React.ReactNode
  value?: string | number
  active?: boolean
  size?: number | Array<number>
  large?: boolean
  small?: boolean
  xl?: boolean
  xs?: boolean
  disabled?: boolean
  style?: React.CSSProperties
  onClick?: (value: string | number) => void
}

const AppCard: React.FC<Props> = (props) => {
  const classes = [
    ...helper.init(props),
    helper.size(props),
    helper.disabled(props),
  ]

  const styles = {
    ...(props.style ?? {}),
  }

  const handleClick = () => {
    if (props.onClick && !props.disabled) {
      props.onClick(props.value ?? '')
    }
  }

  return (
    <div className={cn(classes)} style={styles} onClick={handleClick}>
      {props.children}
    </div>
  )
}

export default AppCard