import values from 'utils/values'

import { Props } from './AppInput'

import global from 'scss/global.module.scss'
import styles from './AppInput.module.scss'

const helper = {
  init: (props: Props) => {
    return [styles.input]
  },

  initWrapper: (props: Props) => {
    return [styles.wrapper]
  },

  active: (active: boolean) => {
    return active ? styles.active : null
  },

  flex: (props: Props) => {
    const defaultValue = null
    const value = values.classWithPrefix(props.flex, 'f')
    return (value ? global[value] : defaultValue)
  },

  widthClass: (props: Props) => {
    const defaultValue = global.wFull
    return (props.full ? global.wFull : null)
      ?? (props.fullWidth ? global.wFull : null)
      ?? (props.halfWidth ? global.wHalf : null)
      ?? defaultValue
  },

  heightClass: (props: Props) => {
    const defaultValue = global.hAuto
    return (props.full ? global.hFull : null)
      ?? (props.fullHeight ? global.hFull : null)
      ?? (props.halfHeight ? global.hHalf : null)
      ?? defaultValue
  },

  width: (props: Props) => {
    const defaultValue = null
    const value = values.px(props.width)
    return value ?? defaultValue
  },

  height: (props: Props) => {
    const defaultValue = null
    const value = values.px(props.height)
    return value ?? defaultValue
  },

  align: (props: Props) => {
    const defaultValue = styles.left
    const value = values.class(props.align)
    return (value ? styles[value] : null)
      ?? (props.center ? styles.center : null)
      ?? (props.left ? styles.left : null)
      ?? (props.right ? styles.right : null)
      ?? defaultValue
  },

  disabled: (props: Props) => {
    const defaultValue = null
    return (props.disabled ? styles.disabled : defaultValue)
  },

  error: (props: Props) => {
    const defaultValue = null
    return (props?.error && (props.error !== '' || props?.danger) ? styles.error : defaultValue)
  },
}

export default helper