import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { request, businessCode } from './index'

export type CustomerType = {
  id?: number
  firstname?: string
  lastname?: string
  avatar?: string
  email?: string
  phone?: string
  self_order?: boolean
  allow_book: boolean
  loaded: boolean
  loggedIn: boolean
  confirmed: boolean
}

type StateType = {
  data: CustomerType
  temp: { phone: string, name: string}
}

const initialState: StateType = {
  data: {
    loaded: false,
    loggedIn: false,
    confirmed: false,
    allow_book: true,
  },
  temp: {
    phone: '',
    name: '',
  },
}

export const customerSlice = createSlice({
  name: '$customer',

  initialState,

  reducers: {
    data: (state: StateType, action: PayloadAction<{allow_book: boolean, customer: CustomerType, status: string}>) => {
      state.data = {
        ...action.payload.customer,
        allow_book: action.payload.allow_book,
        loaded: true,
        loggedIn: state.data.loggedIn,
      }
    },

    loaded: (state: StateType, action: PayloadAction<boolean>) => {
      state.data = {
        ...state.data,
        loaded: action.payload,
      }
    },

    loggedIn: (state: StateType, action: PayloadAction<boolean>) => {
      state.data.loggedIn = action.payload
      if (!action.payload) {
        localStorage.removeItem('customerPhone')
      }
    },

    confirmed: (state: StateType, action: PayloadAction<boolean>) => {
      state.data.confirmed = action.payload
    },

    phone: (state: StateType, action: PayloadAction<string>) => {
      state.temp.phone = action.payload
    },

    name: (state: StateType, action: PayloadAction<string>) => {
      state.temp.name = action.payload
    },

    temp: (state: StateType, action: PayloadAction<{phone: string, name: string}>) => {
      state.temp = action.payload
    },

    logout: (state: StateType) => {
      state.data = initialState.data
      state.temp = initialState.temp

      localStorage.removeItem('customerPhone')
      localStorage.removeItem('customerTemp')
      localStorage.removeItem('sentAt')
    },
  },
})

const get = {
  phone: createSelector([
    state => state.$customer.data,
    state => state.$customer.temp,
  ], (data, temp) => {
    return data?.phone ?? temp.phone
  }),

  name: createSelector([
    state => state.$customer.data,
    state => state.$customer.temp,
  ], (data, temp) => {
    return data?.firstname ?? temp.name
  }),
}

const api = {
  get: (params: object) => {
    return request(`phone/id/${businessCode()}`, 'POST', params)
  },

  sendCode: (params: object) => {
    return request(`send_code/id/${businessCode()}`, 'POST', params)
  },

  checkCode: (params: object) => {
    return request(`check_code/id/${businessCode()}`, 'POST', params)
  },
}

const customer = {
  reducer: customerSlice.reducer,
  set: customerSlice.actions,
  get,
  api,
}

export default customer