import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'store'
import $app from 'store/app'
import $customer from 'store/customer'
import $order from 'store/order'
import $wlist from 'store/wlist'

import App from 'components/App'
import HomeReservations from 'components/Home/HomeReservations'
import HomeWlists from 'components/Home/HomeWlists'

const HomeData = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const phone = useAppSelector($customer.get.phone)
  const customer = useAppSelector(({ $customer }) => $customer.data)
  const orderFetch = useAppSelector(({ $order }) => $order.fetch)
  const wlistFetch = useAppSelector(({ $wlist }) => $wlist.fetch)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (phone && customer.confirmed && (orderFetch || wlistFetch)) {
      fetchData(orderFetch, wlistFetch)

      if (orderFetch) {
        dispatch($order.set.fetch(false))
      }

      if (wlistFetch) {
        dispatch($wlist.set.fetch(false))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone, wlistFetch, orderFetch, customer.confirmed])

  const fetchData = async (orderFetch: boolean, wlistFetch: boolean) => {
    setLoading(true)

    const calls = []
    if (orderFetch) {
      calls.push(fetchOrders())
    }

    if (wlistFetch) {
      calls.push(fetchWlists())
    }

    await Promise.all(calls)

    setLoading(false)
  }

  const fetchOrders = async () => {
    const result = await $order.api.all({ number: phone })
    if (result && result.status === 'success') {
      dispatch($order.set.all(result.data))
    } else {
      dispatch($app.set.error({ message: t(result?.message) }))
    }
  }

  const fetchWlists = async () => {
    const result = await $wlist.api.all({ number: phone })
    if (result && result.status === 'success') {
      dispatch($wlist.set.all(result.data))
    } else {
      dispatch($app.set.error({ message: t(result?.message) }))
    }
  }

  return loading ? (
    <App.Flex center>
      <App.Loader xl />
    </App.Flex>
  ) : (
    <App.Flex column fullWidth gap={8}>
      <HomeReservations />
      <HomeWlists />
    </App.Flex>
  )
}

export default HomeData