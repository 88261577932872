import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { useAppDispatch, useAppSelector } from 'store'
import $resource, { ResourceType } from 'store/resource'

import App from 'components/App'

import styles from './AppointmentResources.module.scss'
import { useEffect } from 'react'

const AppointmentResources: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const resources = useAppSelector($resource.get.filtered)
  const isResourcesDisabled = useAppSelector($resource.get.isDisabled)
  const selectedResourceId = useAppSelector(({ $resource }) => $resource.selectedId)

  useEffect(() => {
    if (selectedResourceId) {
      const resource = resources.find((item: ResourceType) => item.id === selectedResourceId)
      if ( ! resource) {
        dispatch($resource.set.selectedId(null))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResourceId, resources])

  const handleSelect = (id: number | string) => () => {
    if (!isResourcesDisabled) {
      const numberId = Number(id)
      dispatch($resource.set.selectedId(selectedResourceId === numberId ? null : numberId))
    }
  }

  return resources.length ? (
    <App.Flex column gap={4}>
      <App.Text bold height={1}>{t('Select specialist')}:</App.Text>

      <App.Flex row gap={3} className={styles.scroll}>
        {resources.map((item: ResourceType) => {
          return (
            <App.Flex key={item.id} column aCenter gap={3} className={cn(styles.resource, {[styles.active]: selectedResourceId === item.id && !isResourcesDisabled}, {[styles.disabled]: isResourcesDisabled})} onClick={handleSelect(item.id)}>
              <App.Flex center className={styles.photo}>
                <App.Flex center className={styles.inner}>
                  {item.avatar !== '' ? (
                    <img src={item.avatar} alt="" />
                  ) : (
                    <App.Icon icon="user" size={48} />
                  )}

                  <App.Flex center className={styles.check}>
                    <App.Icon icon="check" white size={40} />
                  </App.Flex>
                </App.Flex>
              </App.Flex>

              <App.Text center xs weight={selectedResourceId === item.id ? 'wBold' : 'wMedium'}>{item.name}</App.Text>
            </App.Flex>
          )}
        )}
      </App.Flex>
    </App.Flex>
  ) : null
}

export default AppointmentResources