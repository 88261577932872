import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import cn from 'classnames'

import AppFlex from 'components/App/AppFlex'
import AppText from 'components/App/AppText'
import AppLoader from 'components/App/AppLoader'

import helper from './helper'

export type Props = {
  value: string | number
  rows?: number
  placeholder?: string
  error?: string
  danger?: boolean
  small?: boolean
  large?: boolean
  size?: string | Array<string>
  full?: boolean
  width?: string | number | Array<string | number>
  fullWidth?: boolean
  halfWidth?: boolean
  height?: string | number | Array<string | number>
  fullHeight?: boolean
  halfHeight?: boolean
  flex?: number | Array<number>
  readonly?: boolean
  align?: string | Array<string>
  left?: boolean
  center?: boolean
  right?: boolean
  disabled?: boolean
  limit?: number
  loading?: boolean
  style?: React.CSSProperties
  containerStyle?: React.CSSProperties
  onChange: (value: string) => void
  onPaste?: (event: React.ClipboardEvent<HTMLTextAreaElement>) => void
}

const AppTextarea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const [active, setActive] = useState(false)

  const inputRef = useRef<HTMLTextAreaElement>(null)

  const classes = [
    ...helper.init(props),
    helper.align(props),
  ]

  const styles = {
    ...(props.style ?? {}),
  }

  const containerProps = {
    full: props.full,
    width: props.width,
    fullWidth: props.fullWidth ?? true,
    halfWidth: props.halfWidth,
    height: props.height,
    fullHeight: props.fullHeight,
    halfHeight: props.halfHeight,
    flex: props.flex,
  }

  const wrapperClasses = [
    ...helper.initWrapper(props),
    helper.active(active),
    helper.disabled(props),
    helper.error(props),
  ]

  useImperativeHandle(ref, () => inputRef.current!, [])

  useEffect(() => {
    setActive(props.value !== '')
  }, [props.value])

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let value = event.currentTarget.value

    if (props.onChange && !props.disabled && !props.readonly) {
      props.onChange(value)
    }
  }

  const handleFocus = () => {
    setActive(true)
  }

  const handleBlur = () => {
    setActive(props.value !== '')
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    if (props.onPaste) {
      props.onPaste(event)
    }
  }

  return (
    <AppFlex column gap={1} style={props.containerStyle} {...containerProps}>
      <AppFlex aCenter className={cn(wrapperClasses)}>
        <AppFlex fullHeight flex={1}>
          <textarea
            ref={inputRef}
            value={props.value}
            placeholder={props.placeholder}
            disabled={props.disabled}
            readOnly={props.readonly}
            className={cn(classes)}
            style={styles}
            rows={props.rows}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onPaste={handlePaste}
          />
        </AppFlex>

        {props.loading ? (
          <AppFlex tag="span" aCenter>
            <AppLoader />
          </AppFlex>
        ) : null}
      </AppFlex>

      {props.error && props.error !== '' ? (
        <AppFlex>
          <AppText small danger>{props.error}</AppText>
        </AppFlex>
      ) : null}
    </AppFlex>
  )
})

export default AppTextarea